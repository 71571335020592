@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
.app {
  height: 100%;
  background-color: black;
  @apply text-beige;
}
